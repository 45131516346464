:root {
  --primary-color: #84212A;
  --secondary-color: #FF6600;
  --nav-underline: #EC691F;
  --color-black: #000000;
  --color-white: #FFFFFF;
  --color-ash: #A9A9A9;
  --color-lightshade-ash: #EBECEC;
  --color-grey: #999999;
  --color-brown: #6C4323;
  --color-redpinktext: #FF3939;
}

h1,
h2,
h3,
h4,
h5,
h6,
div {
  font-family: "Oswald-Light";
  /* font-weight: 600 !important; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600 !important;
  text-transform: uppercase;

}

h1,
h2,
h4,
h5,
h6 {
  letter-spacing: 0.12em;
}

h3,
h4.header-section {
  letter-spacing: 0.06em;
}

.letter-spacing-1 {
  letter-spacing: -1px;
}

.letter-spacing-5 {
  letter-spacing: -.5px;
}

a {
  color: unset !important;
  transition: .25s;
}

h4.header-section {
  font-size: 34px;
  font-weight: 500 !important;
}

.error-message {
  color: var(--color-redpinktext);
  font-size: 14px;
  font-weight: 600;
  margin-left: 25px;
}

button.btn {
  font-family: "Oswald-ExtraLight";
  background-color: var(--primary-color);
  border-radius: 35px;
  border: 1px var(--primary-color);
  color: var(--color-white);
  padding: 10px 15px !important;
  letter-spacing: 0.12em;
  font-size: 16px;
  font-weight: 600;
}

button.btn.white-border,
button.btn.white-border:hover {
  font-family: "Oswald-ExtraLight";
  background-color: var(--primary-color) !important;
  border-radius: 35px;
  color: var(--color-white) !important;
  padding: 4px 11px 5px 11px !important;
  letter-spacing: 0.12em;
  font-size: 14px;
  font-weight: 100;
  border: 1px solid var(--color-white);
}

button.btn.ash-border,
button.btn.ash-border:hover {
  font-family: "Oswald-ExtraLight";
  background-color: var(--color-white) !important;
  border-radius: 35px;
  color: var(--color-black) !important;
  /* padding: 4px 11px 5px 11px !important; */
  letter-spacing: 0.12em;
  font-size: 14px;
  font-weight: 100;
  border: 1px solid var(--color-ash);
}

button.btn:hover {
  background-color: var(--secondary-color) !important;
  color: var(--color-black) !important;
  letter-spacing: 0.12em;
  font-size: 16px;
  font-weight: 600;
}

button:focus:not(:focus-visible) {
  box-shadow: unset !important;
}

.hidden {
  display: none !important;
}

.modal-header,
.modal-footer {
  border: 0 !important;
}

p,
a,
span,
label,
th,
td,
ul {
  font-family: "Lato-Light";
}

label {
  font-size: 18px;
}

select {
  cursor: pointer;
}

span {
  font-size: 16px;
  text-align: justify;
}

a {
  transition: .25s;
  text-decoration: none !important;
}

.font-family-latolight {
  font-family: "Lato-Light";
}

.font-family-regular p {
  font-family: "Lato-Regular";
}

h3 {
  font-size: 35px !important;
}

h5 {
  font-size: 22px !important;
}

h6.doctors-names {
  font-size: 24px;
}

h6 {
  font-size: 19px !important;
}

p {
  font-size: 18px;
  text-align: justify;
  margin-bottom: .5rem !important;
}

ul {
  list-style-type: none;

}

ol,
ul {
  padding-left: 1rem !important;
  text-align: left;
}

ul.li {
  font-size: 18px;
  text-align: justify;
  /* list-style-type: none; */
  padding: 0 !important;
}

ul.list-items li {
  list-style-type: disc;
}

li {
  text-align: justify;
}

.text-left {
  text-align: left;
}

p.font-size-22 {
  font-size: 22px;

}

p.font-size-28 {
  font-size: 28px;
}

.bg-white {
  background-color: var(--color-white);
  color: var(--color-black) !important;
}

.italics {
  font-style: italic;
}

.text-capitalize {
  letter-spacing: 0.1rem;
  margin-bottom: 10px;
  font-size: 22px !important;
}

h4.text-capitalize {

  font-size: 1.5rem !important;
}

h3.text-capitalize {
  font-size: 35px !important;
}

.centered-content {
  padding-left: 20%;
  padding-right: 20%;
}

.pxy-40 {
  padding: 80px 30px;
}

.pxy-30 {
  padding: 40px 30px;
}

.px-25 {
  padding: 0 25px;
}

.px-40 {
  padding: 0px 40px;
}

.px-35 {
  padding: 0 35px;
}

.pxy-75 {
  padding: 80px 75px;
}

.py-20 {
  padding: 0px 0 20px 0;
}

.py-18 {
  padding: 18px 0;
}

.py-40 {
  padding: 40px 0 40px 0;
}

.pt-40 {
  padding: 40px 0 0px 0;
}

.row-25>* {
  margin-bottom: 25px;
}

.row-5>* {
  margin-bottom: 5px;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

p.mb-40 {
  margin-bottom: 40px !important;

}

.mb-55 {
  margin-bottom: 55px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.my-20 {
  margin: 20px 0;
}

.ml-25 {
  margin-left: 25px;
}

.mt-70 {
  margin-top: 70px !important;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-30 {
  margin-top: 30px;
}

.border-radius img {
  border-radius: 8px;
}

.other-page-banner {
  color: var(--color-white);
}

.card {
  border-radius: 8px;
  transition: box-shadow .3s ease;
}

.card:hover {
  box-shadow: 0 0 12px rgba(110, 123, 157, 0.5);
  /* height: auto; */
}

.img-container {
  width: 80%;
  margin: auto;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.display-inline {
  display: inline;
}

label {
  display: block !important;
  margin-bottom: 10px;
  margin-left: 18px;
}

.text-justify {
  text-align: justify;
}

.text-bold {
  font-weight: bold;
}

.w-50 {
  width: 50%;
}

/* //carousel css */

.carousel-card-main {
  padding: 2%;
}

p.carousel-title,
a.carousel-title {
  font-size: 19px;
  color: var(--color-black);
  text-decoration: none;
}

p.product-title-ht {
  /* height: 50px; */
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -1px;
  overflow: hidden;
  /* text-overflow: ellipsis;
  white-space: nowrap; */
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

a.carousel-title {

  font-weight: bold;
}

p.carousel-paragraph {
  font-size: 24px;
}

.show-arrow .slick-prev:before,
.show-arrow .slick-next:before {
  font-family: 'slick';
  font-size: 26px !important;
  line-height: 1;
  opacity: 1;
  color: grey !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev,
.slick-next {
  height: 25px !important;
  color: var(--color-ash) !important;
}

.slick-prev:hover,
.slick-next:hover {
  height: 25px !important;
  color: var(--primary-color) !important;
}

svg.svg-inline--fa.fa-arrow-left.slick-arrow.slick-prev,
svg.svg-inline--fa.fa-arrow-right.slick-arrow.slick-next {
  display: block;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  padding: 11px 10px;
  background-color: #f2f3f9;
  color: var(--color-white) !important;
  cursor: pointer;
}

svg.svg-inline--fa.fa-arrow-right.slick-arrow.slick-next:hover,
svg.svg-inline--fa.fa-arrow-left.slick-arrow.slick-prev:hover {
  display: block;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  padding: 11px 10px;
  background-color: var(--secondary-color);
  color: var(--color-white) !important;
  cursor: pointer;

}

svg.svg-inline--fa.fa-arrow-left.slick-arrow.slick-prev.slick-prev {
  left: -50px !important;
}

svg.svg-inline--fa.fa-arrow-right.slick-arrow.slick-next.slick-next {
  right: -50px !important;
}

.card-carousel-main:hover {
  height: auto;
  border-radius: 8px;

}

.carousel-card-nav.card {
  display: flex;
  flex-direction: column;
  /* gap: 12px; */
  overflow: hidden;
  border: none;
  border-radius: 8px;
  /* box-shadow: 0 0 1px rgba(110, 123, 157, 0.5); */
  transition: box-shadow .3s ease;

}

.carousel-card-nav .img-container {
  overflow: hidden;
  width: 100%;
  /* height: auto; */
  transition: .5s;
  /* height: 350px !important; */
}

.carousel-card-nav .img-container img {
  width: 100%;
  transition: transform 2s ease;
  /* border-radius: 8px; */

}

.carousel-card-nav .img-container:hover img {

  transform: scale(1.1);
}

.carousel-card-nav.card:hover {
  box-shadow: 0 0 12px rgba(110, 123, 157, 0.5);
  height: auto;
  /* transition: transform 6s ease; */

}

.carousel-card-nav .carousel-content {
  padding: 20px 25px;
}

.carousel-card-nav p {
  margin-bottom: 10px;
}

.carousel-card-nav img {
  cursor: pointer;

}

.carousel-card-nav button.btn {
  cursor: pointer;
  border-radius: 35px;
  background-color: var(--primary-color);
  color: var(--color-white);
  border: none;
  font-family: "Lato-Light";
  font-size: 14px;
  padding: 4px 11px;
}

.carousel-card-nav button.btn:hover {

  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;

}

.carousel-card-nav {
  /* margin-right: 30px; */
  margin: 10px;
}

/* //about our products and about us card section css starts, oral-supplements */

.about-ourproducts,
.our-products,
.aboutus-card-section,
.oral-supplements,
.services-institute-div,
.card-section,
.formulated-science-description,
.cosmetic-products,
.service .service-description.product-description,
.skincare-cosmeticproducts,
.bottle-packaging-div,
.manufacturing-blogs-section,
.product-review-page,
.faq {
  background-color: var(--color-lightshade-ash);
}

/* //border bottom line for all pages */

.header-bottom-line h3,
h5 {
  position: relative;
}

.header-bottom-line-left h3 {
  position: relative;
}

.header-bottom-line h3::after,
h5::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -5px;
  width: 80px;
  border-bottom: 3.5px solid var(--secondary-color);
  transform: translateX(-50%);
}

.header-bottom-line-left h3::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 80px;
  border-bottom: 3.5px solid var(--secondary-color);
  /* transform: translateX(-50%); */
}

/* //table css  */
.formulation-experience-table th,
td {
  text-align: left;
  border-width: 0px !important;
}

.formulation-experience-table th {
  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;
  font-weight: 100 !important;
}

.formulation-experience-table th:nth-child(1),
td:nth-child(1) {
  text-align: center !important;
}

/* Style odd rows */
.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: var(--bs-table-bg) !important;
}

/* Style even rows */
.table-striped>tbody>tr:nth-of-type(even)>* {
  --bs-table-bg-type: var(--bs-table-striped-bg) !important;
}

/* //cardsection css */

/* .box-minimal-modern .box-minimal-title {
  margin-top: 30px;
}

.box-minimal-modern .box-minimal-text {
  margin-top: 22px;
} */

.row-50>* {
  margin-bottom: 50px;
}

.row-75>* {
  margin-bottom: 75px;
}

/* //form checkbox */
.form-check .form-check-input {
  border: 1px solid black;
}

.form-check .form-check-input:focus {
  border-color: unset !important;
  box-shadow: none !important;
}

.form-select:focus {
  border-color: unset !important;
  outline: 0;
  box-shadow: unset !important;
}