.card-section-full-img .card {
    min-height: 445px;
    border-radius: 8px;
}

.card-section-full-img .card-body {
    padding: 25px 38px;
}

.card-section-full-img .card-body p {
    text-align: justify;
    letter-spacing: -.5px;
}

.card-section-full-img .img-container-full {
    overflow: hidden;
    transition: .5s;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.card-section-full-img .img-container-full img {
    transition: transform 2s ease;
    display: block;
}

.card-section-full-img .img-container-full:hover img {
    transform: scale(1.1);
}

/* // product  page */

.last-card-speciality-box .card {
    min-height: auto;
    border-radius: 8px;
}

.virtual-product .card h6 {
    color: var(--primary-color);
}

.virtual-product .search-bar button.btn {
    border-radius: 50%;
    width: 50px;
}

.virtual-product .search-bar .search-form input {
    border: none;
}

/* //accordian section css  */

.accordian-card-section.card-group-custom {
    display: flex;
    flex-direction: column;
}

*+.card-group-custom {
    margin-top: 50px;
    margin-bottom: 50px;
}

.accordian-card-section.card-group.card-group-corporate .card {
    border-radius: 10px;
}

.accordian-card-section.card-group.card-group-corporate .card+.card {
    margin-top: 8px;
}

.accordian-card-section.card-group-custom .card-custom {
    border: unset;
}

.accordian-card-section .card-corporate {
    position: relative;
    text-align: left;
    background: #ffffff;
    box-shadow: 0px 3px 12px 0px rgba(220, 222, 236, 0.5);
    min-height: auto !important;
}

.accordian-card-section .card-title {
    margin-bottom: 0;
}

.accordian-card-section .card-corporate .card-title a {
    padding: 18px 82px 18px 32px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0;
    color: #242429;
    transition: inherit;
    border-radius: 10px 10px 0 0;
}

.accordian-card-section .card-custom a {
    display: block;
}

.accordian-card-section .card-corporate .card-title a .card-arrow {
    transition: .33s;
}

.accordian-card-section .card-corporate .card-arrow {
    position: absolute;
    top: 0;
    bottom: -1px;
    right: -1px;
    z-index: 2;
    width: 70px;
    background: linear-gradient(90deg, var(--primary-color) 0%, #f44051 100%);
    border-radius: 0 10px 10px 0;
    transition: 750ms ease-in-out;
}

.accordian-card-section .card-corporate .card-arrow .minus-icon {
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.33s;
}

.accordian-card-section .card-corporate .card-arrow .plus-icon {
    color: #ffffff;
    opacity: 1;
    transition: opacity 0.33s;
}

.accordian-card-section .card-arrow svg {
    position: absolute;
    top: 22px;
    left: 50%;
    transform: translateX(-50%);
}

.accordian-card-section .card-corporate .card-arrow {
    position: absolute;
    top: 0px;
    right: 0%;
    z-index: 4;
    color: #ffffff;
    transition: .22s;
}

.accordian-card-section .card-corporate .collapse {
    color: #838386;
    border-radius: 0 0 10px 10px;
    transition: max-height 0.5s ease-in-out;

}

.accordian-card-section .card-corporate .card-body {
    padding: 0 82px 25px 32px;
    padding-right: 100px;
}

.accordian-card-section .card-corporate .card-body p {
    text-align: justify;
}

/* // product  page css ends */

/* //cosmetic product page css starts */

.service .service-description.product-description {
    /* background-color: var(--primary-color); */
    padding: 25px 40px;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.cosmetic-products-science .formulated-science-description {
    padding: 40px;
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.cosmetic-products-science .formulated-science-div {
    padding: 40px;
    color: var(--color-white);
    background-color: var(--primary-color);
}

.cosmetic-products-science .formulated-science .d-flex {
    gap: 20px;
}

.row.formulated-science {
    margin: auto;
}

.carousel-card-nav.card.cosmetic-product-card .carousel-content {
    padding: 20px 15px;
}

.videobtn button.btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 2px solid white;
    padding: 6px 12px !important;
}

.cosmetic-video .videobtn button.btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--color-redpinktext);
    border: 2px solid var(--color-redpinktext);
    padding: 6px 12px !important;
}

.videobtn button.btn:hover {
    border: unset;
    background-color: #00000094 !important;
    color: white !important;
}

.carousel-card-nav.card.cosmetic-product-card.mx-auto:hover {
    box-shadow: 0 0 12px rgba(110, 123, 157, 0.5);
    min-height: 425px;
}

.cosmetic-img-div img {
    max-width: fit-content;
    height: auto;
}

/* //cosmetic product page css ends */

/* //stock product page css starts */

.skincare-cosmetic-tabs .nav-item a.active,
.skincare-cosmetic-tabs .nav-item a:hover {
    background-color: var(--primary-color);
    color: var(--color-white) !important;
    border-radius: 35px;
    padding: 10px 35px;
}

.skincare-cosmetic-tabs .nav-item a {
    background-color: var(--color-white);
    color: var(--color-black);
    border-radius: 35px;
    padding: 10px 35px;
}

.skincare-cosmetic-tabs {
    gap: 15px;
}


.directionof-use-capsule button.btn,
.directionof-use-capsule button.btn:hover {
    background-color: var(--primary-color) !important;
    color: var(--color-white) !important;
    margin: 5px 10px 5px 0;
}

.stockproducts-pagination {
    gap: 10px;
}

.stockproducts-pagination ul {
    padding: 0;
}

.stockproducts-pagination .page-link {
    border-radius: 50%;
    padding: 10px 12px;
    width: 45px;
    height: 45px;
    font-weight: 600;
    text-align: center;
}

.stockproducts-pagination .page-item.active .page-link,
.stockproducts-pagination .page-item.active .page-link:focus {
    background-color: var(--primary-color);
    border: 1px solid var(--primary-color);
    color: var(--color-white) !important;
    font-weight: 600;
    outline: unset !important;
    box-shadow: none !important;

}

.stockproducts-pagination .page-item:hover .page-link,
.stockproducts-pagination .page-item:hover .page-link:focus {
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: var(--color-black) !important;
    font-weight: 600;
    box-shadow: none !important;

}

.stockproducts-pagination li:first-child.page-item .page-link,
.stockproducts-pagination li:first-child.page-item:hover .page-link,
.stockproducts-pagination li:last-child.page-item .page-link,
.stockproducts-pagination li:last-child.page-item:hover .page-link {
    background: unset;
    border: unset;
    box-shadow: unset;
}

/* //stock product page css ends */

/* speciality products page css starts */

.professionals-offering-checkbox .form-check-input[type=checkbox] {
    border-radius: 0px !important;
}

.tabbutton-wrapper {
    overflow-x: auto;
    white-space: nowrap;
}

.tabbutton-item {
    display: inline-block;
    margin-right: 10px;
    width: 170px;
}

.tabbutton {
    margin-top: 30px;
}

.tabbutton .speciality-product-names {
    background-color: var(--color-white);
    color: var(--color-grey);
    border: 1px solid var(--color-grey);
    font-size: 16px;
    padding: 10px !important;
    letter-spacing: normal;
    border-radius: 35px;
    cursor: pointer;
    font-weight: 400;
}

.tabbutton .speciality-product-names.active,
.tabbutton .speciality-product-names:hover {
    background-color: var(--primary-color) !important;
    color: var(--color-white) !important;
    font-size: 16px;
    padding: 10px !important;
    letter-spacing: normal;
    border: 1px solid var(--primary-color);
}

.oral-care-range ol,
.oral-care-range li,
.oral-care-range ol.li,
.manufacturing-packaging li {
    list-style-type: decimal;
    padding-left: 4px;
}

.oral-care-range ol,
.manufacturing-packaging ol {
    margin-bottom: 0 !important;

}

/* speciality products page css ends */

/* nootropic prouducts css starts */

.nootropic-product-names.text-left.product-names,
.nootropic-product-names.text-left.product-names:hover {
    color: var(--color-grey) !important;
    cursor: default;
    font-size: 17px;
}

.nootropic-product-names {
    padding: 15px;
    font-family: "Lato-Light";
    cursor: pointer;
}

.nootropic-product-names:hover,
.nootropic-product-names.active {
    background-color: var(--color-lightshade-ash);
}

.tabbutton-scroll {
    overflow-x: auto;
    white-space: nowrap;
}

.oral-products-div img {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 3 / 4.8; */
    object-fit: cover;
}

/* nootropic products css ends */

/* virtual product review page css starts */

.virtualproductreview-page-banner-css {
    position: relative;
}

.product-review-images {
    position: absolute;
    top: 60%;
    bottom: 15%;
    left: 45%;
    gap: 50px;
    display: flex;
    transform: translate(-50%, -50%);
}

.product-review-images img {
    cursor: pointer;
    width: 175px;
    height: max-content;
}

/* .product-review-images {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
}

.product-review-images img {
    cursor: pointer;
    width: 100px;
    height: auto;
    margin: 0 10px;
} */

.redirection-link {
    position: absolute;
    top: 50%;
    right: -100px;
    rotate: -90deg;
    /* left: 88%; */

}

.redirection-link a {
    font-family: "Oswald-ExtraLight";
    background-color: var(--primary-color);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    color: var(--color-white) !important;
    padding: 10px 15px 20px 15px !important;
    letter-spacing: 0.12em;
    font-size: 16px;
    font-weight: 600;
}

.product-review-page.header-bottom-line-left {
    position: relative;
}

.product-review-page .other-image-div img {
    cursor: pointer;
}

.product-review-page .otherimg-card {
    cursor: pointer;
}

.product-img-card {
    min-height: 590px;
}

.otherimg-card {
    max-width: 100%;
    height: 186px;
    overflow: hidden;
}

.otherimg-card .other-image-div {
    width: 100%;
    height: 100%;
}

.otherimg-card .other-image-div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}



/* .product-review-page .product-img-div {
    width: 400px;
    margin: auto;
    height: fit-content;
}

.product-review-page .product-img-card {
    width: 100%;
    height: 100vh;
} */

/* virtual product review page css ends */