/* //media query */

@media only screen and (max-width: 430px) {
    h1 {
        font-size: 18px;
    }

    .home-banner-text-btn h4 {
        font-size: 16px;
    }

    .home-banner-text-btn h1 {
        margin-bottom: 0;
    }

    .home-banner-text-btn {

        margin: -3px 50px;
    }

    p,
    ul,
    li {
        font-size: 16px;
    }

    p.font-size-22,
    h6 {
        font-size: 18px;
    }

    p.carousel-paragraph {
        font-size: 18px;
    }

    h3 {
        font-size: 24px !important;
    }

    h4 {
        font-size: 22px;
    }

    h5 {
        font-size: 20px !important;
    }

    .header-bottom-line h3 {
        width: 100%;
    }

    .pxy-40 {
        padding: 60px 10px;
    }

    .pxy-75 {
        padding: 60px 20px;
    }

    .pxy-40.px-0 {
        padding: 60px 10px !important;
    }

    .pxy-40.px-0.pt-0 {
        padding-top: 0 !important;
    }

    .pxy-0 {
        padding: 0 20px;
    }

    .pxy-40.baby-care-div {
        padding: 60px 20px 0px 20px !important;
    }

    .why-choose-us-row {
        gap: 18px
    }

    .py-40 {
        padding: 20px 0 20px 0;
    }

    .mt-70 {
        margin-top: 40px !important;
    }

    .mt-28 {
        margin-top: 18px;
    }

    .row-25>* {
        margin-bottom: 15px;
    }

    .img-div {
        width: 50%;
        margin: auto;
    }

    .mx-100 {
        width: 0 50px !important;
    }

    .carousel-card-nav {
        margin-right: 0px;
    }

    .certification-logo-section img {
        width: 40px;
    }

    .home-banner-text-btn {
        bottom: 23px !important;
    }

    .home-banner-text-btn.other-page-banner {
        top: 200px;
    }

    .aboutus-card-section .card-gap {
        gap: 15px;
    }

    .img-container {
        width: 100%;
        margin: auto;
    }

    /* //get in touch section */
    .get-in-touch .px-35 {
        padding: 0px;
    }

    .get-in-touch input,
    .get-in-touch textarea,
    .get-in-touch input:-webkit-autofill,
    .get-in-touch input:focus-visible {
        margin-bottom: 5px !important;
    }

    /* //cardsection */
    .box-minimal-modern {

        min-height: auto !important;
    }

    .slick-prev {
        left: -10px !important;
        z-index: 999;
    }

    .slick-next {
        right: -10px !important;
        z-index: 999;
    }

    .cosmetic-products .card {
        min-height: auto !important;
    }

    .service .service-description {
        padding: 20px 20px !important;
    }

    .nootropic-product-names {

        display: inline-block;
    }

    .nootropic-product-names {

        border-Bottom: 0px solid var(--color-grey);

    }

    .tabbutton .speciality-product-names {

        font-size: 14px !important;

    }

    .nootropic-product-names {
        font-size: 14px;
    }

    .centered-content {
        padding-left: 10%;
        padding-right: 10%;
    }

    .bottle-packaging-width.w-50 {
        width: auto !important;
    }

    .social-media-section {
        flex-direction: column;
    }

    p.font-size-28 {
        font-size: 22px;
    }

    .product-review-images {
        position: absolute;
        top: 47% !important;
        bottom: 40% !important;
        left: 43% !important;
        gap: 15px !important;
        display: flex;
        transform: translate(-50%, -50%);
    }

    .product-review-images img {
        cursor: pointer;
        width: 45px !important;
        height: max-content !important;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .time-line-vertical-element .unit-left {
        /* max-width: 100px; */
        width: 100%;
    }

    .unit {
        /* display: flex; */
        flex: 0 1 100%;
        /* margin-bottom: -30px; */
        /* margin-left: -20px; */
    }

    .unit>* {
        /* margin-bottom: 30px; */
        margin-left: 0px;
    }

    .unit-left {
        display: flex;
        /* flex-direction: column; */
        gap: 50px;
        align-items: center;
        justify-content: center;
    }

    .time-line-vertical-element {
        /* position: relative; */
        /* padding-left: 35px; */
        margin-bottom: 80px;
    }

    .virtual-img-container {
        width: 30%;
        height: 100%;
        margin: auto;
    }

    .product-img-div {
        height: 200px;
        width: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .product-img-div img {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
    }

    .unit.blog-lists-div .border-radius {
        min-height: 100px;
    }

    .row-75>* {
        margin-bottom: 35px !important;
    }
}

@media screen and (min-width: 430px) and (max-width: 780px) {

    .box-minimal-modern {

        min-height: 430px !important;
    }

    article.box-minimal-modern.quality-control-card {
        min-height: 400px !important;
    }

    .box-mini-minimal .box-minimal-modern {
        min-height: 250px !important;
    }

    article.box-minimal-modern.carousel-card {
        min-height: 440px !important;
    }

    article.box-minimal-modern.oralsupplements-card {
        min-height: 300px !important;
    }

    .cosmetic-products .card {
        min-height: 700px !important;
    }

    .popular-tests .card {
        min-height: 912px !important;
    }

    .nootropic-product-names {

        display: inline-block;
    }

    .nootropic-product-names {

        border-Bottom: 0px solid var(--color-grey);

    }

    .centered-content {
        padding-left: 10%;
        padding-right: 10%;
    }

    .bottle-packaging-width.w-50 {
        width: auto !important;
    }

    .product-review-images img {
        cursor: pointer;
        width: 100px !important;
        height: max-content !important;
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .time-line-vertical-element .unit-left {
        max-width: 100px;
        width: 100%;
    }

    .unit {
        display: flex;
        flex: 0 1 100%;
        margin-bottom: -30px;
        margin-left: -20px;
    }

    .unit>* {
        margin-bottom: 30px;
        margin-left: 20px;
    }

    .display-picture-div .display-img-div::after {
        content: '';
        position: absolute;
        right: -45px;
        top: 0;
        bottom: 5vh;
        border-right: 1px solid var(--color-grey);
        margin-left: -1px;
        transform: rotateZ(90deg);
    }

    .display-picture-div::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 160px;
        bottom: -400px;
        border-left: 1px solid var(--color-grey);
        margin-left: -1px;
    }

    .unit-left {
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
    }

    .time-line-vertical-element {
        position: relative;
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 780px) {

    .header-bottom-line h3 {
        width: 100%;
    }

    .home-banner-text-btn {
        bottom: 80px;
    }

    .why-choose-us-row {
        gap: 18px;
    }

    .home-banner-text-btn.other-page-banner {
        bottom: 70%;
    }

    svg.svg-inline--fa.fa-arrow-left.slick-arrow.slick-prev,
    svg.svg-inline--fa.fa-arrow-right.slick-arrow.slick-next {
        display: none !important;

    }

    /* .oral-supplements-row {
        gap: 18px;
    } */

    p.font-size-22 {
        font-size: 18px;
    }

    p.carousel-paragraph {
        font-size: 18px;
    }

    .navigation-bar .pb-0 {
        padding-bottom: 8px !important;
    }

    .navigation-bar .dropdown-menu {
        position: static !important;
        width: auto !important;
        margin-top: 0 !important;
        box-shadow: none !important;
    }

    .navigation-bar .dropdown-menu .dropdown-menu {
        position: static !important;
        transform: none !important;
    }

    .navigation-bar a.nav-link,
    a.dropdown-item {
        text-align: center;
        margin: auto;
        padding-right: 10px;
        padding-left: 10px;
    }

    .dropdown {
        margin: auto;
    }

    .services-institute-div .d-flex {
        flex-direction: column;
        align-items: center;
        gap: 5px !important;
    }

    .services-institute-div .service-content {
        text-align: center;
    }

    .service .service-image-card {
        padding: 0px;
    }

    .px-25 {
        padding: 0px;
    }

    .fa-nav-bars {
        color: white;
        font-size: larger;
        margin: 0 10px;
    }

    .card-section-full-img .last-card-speciality-box .img-container-full {
        overflow: hidden;
        transition: .5s;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        /* border-bottom-left-radius: 0px; */
    }

    .our-products .view-all button.btn,
    .skincare-cosmeticproducts .view-all button.btn {
        padding: 10px 35px !important;
    }

    .slick-dots li button:before {
        font-family: 'slick';
        font-size: 12px !important;
    }

    .rnd-img-div {
        display: flex;
        justify-content: center;
    }

}



@media only screen and (min-width: 768px) {
    /* .accordian-card-section *+.card-group-custom {
        margin-top: 50px;
        margin-bottom: 50px;
    } */

    .accordian-card-section .card-corporate .card-title a {
        font-size: 24px;
        line-height: 1.41667;
    }

    .accordian-card-section .card-corporate .card-body {
        padding-right: 150px;
    }

    /* .speciality-card-flex {
        display: flex;
    } */

    *+.time-line-vertical {
        margin-top: 45px;
    }

    .time-line-vertical-element {
        padding-left: 0;
    }

    .unit-spacing-xxl {
        margin-bottom: -20px;
        margin-left: -115px;
    }

    .time-line-vertical-element .unit-left {
        max-width: 200px;
        width: 100%;
        /* text-align: right; */
    }


    .unit-spacing-xxl>* {
        margin-bottom: 20px;
        margin-left: 115px;
    }

    .time-line-vertical-element .unit-body {
        position: relative;
    }

    /* .time-line-vertical-element .unit-body:before {
        content: '';
        position: absolute;
        z-index: 1;
        left: -60px;
        top: 8px;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background-color: var(--color-white);
        border: 2px solid var(--primary-color);
    } */

    .time-line-vertical-element .time-line-content {
        position: relative;
        top: -3px;
    }

    /* .time-line-vertical-element:last-child .unit-body:after {
        content: '';
        position: absolute;
        left: -54px;
        top: -970vh;
        bottom: 0vh;
        border-left: 2px solid var(--primary-color);
    } */

    .radio-button-division {
        display: flex;
        align-items: center;
        gap: 12px;
    }

    .unit {
        display: flex;
        flex: 0 1 100%;
        /* margin-bottom: -30px; */
        margin-left: -20px;
    }

    .unit>* {
        /* margin-bottom: 30px; */
        margin-left: 20px;
    }

    .display-picture-div .display-img-div::after {
        content: '';
        position: absolute;
        right: -45px;
        top: 0;
        bottom: 5vh;
        border-right: 1px solid var(--color-grey);
        margin-left: -1px;
        transform: rotateZ(90deg);
    }

    .display-picture-div::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 160px;
        bottom: -300px;
        border-left: 1px solid var(--color-grey);
        margin-left: -1px;
    }

    .unit-left {
        display: flex;
        flex-direction: column;
        gap: 50px;
        align-items: center;
    }

    .time-line-vertical-element {
        position: relative;
        margin-bottom: 50px;
    }

    .virtual-img-container {
        width: 100%;
        height: 100%;
    }

    /* .card.mb-3.otherimg-card {
        height: 210px !important;
    } */
    .product-img-div {
        height: 273px;
        width: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .product-img-div img {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
    }

    .unit.blog-lists-div .border-radius {
        min-height: 330px;
    }

}

@media screen and (min-width: 768px) and (max-width: 900px) {
    .testimonail-img-div {
        height: auto;
        overflow: hidden;
        width: 85%;
    }
}

@media only screen and (min-width: 900px) {
    .mx-45 {
        margin: 0 0 0 45px;
    }

    .mx-100 {
        margin: 0 100px;
    }

    h1 {
        font-size: 3.2rem;
    }

    .box-minimal-modern {
        min-height: auto !important;
    }

    /* .fa-nav-bars {

        display: none;
    } */

    .speciality-card-flex {
        display: flex;
    }

    .card-section-full-img .last-card-speciality-box .img-container-full {
        overflow: hidden;
        transition: .5s;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 8px;
    }

    .slick-dots li button:before {
        display: none !important;
    }

    .nootropic-product-names {
        border-Bottom: 1px solid var(--color-grey);

    }

    .tabsection-margin {
        margin-top: 70px;
    }

    .footer-section .row-50>* {
        margin-bottom: 0px !important;
    }

    .mx-275 {
        margin: 0 275px;
    }



}

@media only screen and (min-width: 1020px) {

    .home-banner-text-btn.other-page-banner {
        top: 35%;
    }

    .embed-responsive {
        width: 105.5%;
    }

    /* .video-container-div {
        height: 314px;
        width: 100%;
    } */
    /* .cosmetic-products .card {
        min-height: 800px !important;
    } */
    *+.time-line-vertical {
        margin-top: 80px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {
    .box-minimal-modern {

        min-height: 620px !important;
    }

    .box-mini-minimal .box-minimal-modern {
        min-height: 320px !important;
    }

    article.box-minimal-modern.carousel-card {
        min-height: 630px !important;
    }

    article.box-minimal-modern.quality-control-card {
        min-height: 550px !important;
    }

    article.box-minimal-modern.oralsupplements-card {
        min-height: 430px !important;
    }

    .video-container-div {
        height: fit-content;
        width: auto;
    }

    .oral-products-div img {
        width: 100%;
        height: 100%;
        aspect-ratio: 2 / 3.8;
        object-fit: cover;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1100px) {

    .cosmetic-products .card {
        min-height: 850px !important;
    }

    .scroll-behaviour {
        overflow-y: scroll;
        max-height: 120vh;
    }

    .scroll-behaviour2 {
        overflow-y: scroll;
        max-height: 110vh;
    }

    .testimonail-img-div {
        height: auto;
        overflow: hidden;
        width: 85%;
    }
}

@media screen and (min-width: 1100px) and (max-width: 1200px) {
    .video-container-div {
        height: 320px !important;
        width: 122%;
    }

    .cosmetic-products .card {
        min-height: 850px !important;
    }

    .scroll-behaviour {
        overflow-y: scroll;
        max-height: 120vh;
    }

    .scroll-behaviour2 {
        overflow-y: scroll;
        max-height: 110vh;
    }

    .oral-products-div img {
        width: 100%;
        height: 100%;
        aspect-ratio: 2.5 / 4.8 !important;
        object-fit: cover;
    }

    .testimonail-img-div {
        height: auto;
        overflow: hidden;
        width: 85%;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .cosmetic-products .card {
        /* min-height: 800px !important; */
        min-height: 720px !important;

    }

    .popular-tests .card {
        min-height: 735px !important;
    }

    .product-review-images img {
        cursor: pointer;
        height: max-content !important;
    }

    .scroll-behaviour {
        overflow-y: scroll;
        max-height: 109vh;
    }

    .scroll-behaviour2 {
        overflow-y: scroll;
        max-height: 100vh;
    }

    .testimonail-img-div {
        height: auto;
        overflow: hidden;
        width: 85%;
    }
}

@media screen and (min-width: 1110px) and (max-width: 1400px) {
    .box-minimal-modern {

        min-height: 510px !important;
    }

    .box-mini-minimal .box-minimal-modern {
        min-height: 270px !important;
    }

    article.box-minimal-modern.carousel-card {
        min-height: 520px !important;
    }

    article.box-minimal-modern.quality-control-card {
        min-height: 400px !important;
    }

    article.box-minimal-modern.oralsupplements-card {
        min-height: 330px !important;
    }

    .video-container-div {
        height: 315px;
        /* width: 122%; */
    }

    /* .cosmetic-products .card {
        min-height: 750px !important;
    } */
    .oral-products-div img {
        width: 100%;
        height: 100%;
        aspect-ratio: 3 / 4.8;
        object-fit: cover;
    }

    /* .scroll-behaviour {
        overflow-y: scroll;
        max-height: 100vh;
    } */
    .product-img-div {
        height: 273px;
        width: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .product-img-div img {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
    }

}

@media only screen and (min-width: 1400px) {

    .home-banner-text-btn.other-page-banner {
        top: 50%;
    }

    .home-banner-text-btn {
        margin: 0 110px;
        bottom: 35% !important;
    }

    .box-minimal-modern {
        min-height: 400px !important;
    }

    article.box-minimal-modern.carousel-card {
        min-height: 465px !important;
    }

    .box-mini-minimal .box-minimal-modern {
        min-height: 250px !important;
    }

    article.box-minimal-modern.quality-control-card {
        min-height: 350px !important;
    }

    .box-mini-minimal .box-minimal-modern {
        min-height: 300px !important;
    }

    .service .service-description {
        padding: 40px !important;
    }

    .video-container-div {
        height: 365px;
        width: 100%;
    }

    .cosmetic-products .card {
        min-height: 700px !important;
    }

    .popular-tests .card {
        min-height: 740px !important;
    }

    .product-review-images img {
        cursor: pointer;
        height: max-content !important;
    }

    .irtual-product-btn {
        margin-top: 45px !important;
    }

    .oral-products-div img {
        width: 100%;
        height: 100%;
        aspect-ratio: 3 / 4.1;
        object-fit: cover;
    }

    .scroll-behaviour {
        overflow-y: scroll;
        max-height: 100vh;
    }

    .scroll-behaviour2 {
        overflow-y: scroll;
        max-height: 95vh;
    }

    .product-img-div {
        height: 350px !important;
        width: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .product-img-div img {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
    }

    .testimonail-img-div {
        height: auto;
        overflow: hidden;
        width: 80%;
    }

    .product-review-images {
        top: 50% !important;
        bottom: 50% !important;
        left: 50% !important;

    }
}

@media screen and (min-width: 1500px) and (max-width: 2560px) {

    .box-minimal-modern {

        min-height: 340px !important;
    }

    article.box-minimal-modern.quality-control-card {
        min-height: 300px !important;
    }

    .scroll-behaviour {
        overflow-y: scroll;
        max-height: 55vh;
    }

    .scroll-behaviour2 {
        overflow-y: scroll;
        max-height: 50vh;
    }

    .product-img-div {
        height: 350px !important;
        width: auto;
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .product-img-div img {
        max-width: 100%;
        height: auto;
        display: block;
        object-fit: contain;
    }

}

@media only screen and (min-width: 2560px) {

    .oral-supplements .card {
        min-height: 600px !important;

    }

    .scroll-behaviour {
        overflow-y: scroll;
        max-height: 55vh;
    }

    .scroll-behaviour2 {
        overflow-y: scroll;
        max-height: 50vh;
    }
}