/* //why choose us css starts */


.header-bottom-line h3 {
    /* width: 25%; */
    margin: auto;
}

.why-chooseus div {
    position: relative;
}

.why-chooseus .whychooseus-divisions {
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    transition: 450ms ease-in-out;
    color: var(--primary-color);
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    font-weight: 600;
}

.number {
    font-size: 48px;
}

.divisions:hover .number {
    color: white;
    transition: 450ms ease-in-out;

}

.whychooseus-divisions::before,
.whychooseus-divisions::after {
    position: absolute;
    content: "";
    display: inline-block;
    border-radius: 50%;
    transition: 450ms ease-in-out;
    pointer-events: none;
}

.whychooseus-divisions::before {
    top: 50%;
    bottom: 50%;
    left: 50%;
    right: 50%;
    opacity: 0;
    background: var(--primary-color);
}

.whychooseus-divisions::after {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 2px solid var(--secondary-color);

}

.divisions:hover .whychooseus-divisions::before {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 1;
}

/* //testimonials css starts */
.testimonials .left-quote-icon {
    font-family: "BIZUDPGothic-Bold";
    transform: rotate(180deg);
    text-align: end;
    font-size: 85px;
    color: var(--secondary-color);
    position: relative;
    right: 5px;
    height: 50px;
}

.testimonials h6 {
    color: var(--secondary-color);
}

.testimonials-customer-details p {
    font-size: 16px;
    color: var(--color-ash);
}

.testimonial-carousel {
    display: flex !important;

}

/* //service css starts */
.services-carousel .carousel-title .span2 {
    color: var(--color-redpinktext);
}

/* //our products css starts */

.our-products .products-title,
.skincare-cosmeticproducts .products-title {
    background-color: var(--primary-color);
    color: var(--color-white);
    padding: 20px;
    border-top-right-radius: 9px;
    border-top-left-radius: 9px;
    letter-spacing: normal;
}

.row-30 {
    margin-bottom: 30px;
}

.product,
.product-button {
    transition: .50s cubic-bezier(0.55, 0.09, 0.68, 0.53) all;
}

.our-products .product,
.skincare-cosmeticproducts .product {
    position: relative;

}

.our-products .product:hover,
.skincare-cosmeticproducts .product:hover {
    z-index: 2;
    box-shadow: 0 5px 12px 0 #dcdeec;
    margin-bottom: -60px;
}

.our-products .product-button,
.skincare-cosmeticproducts .product-button {
    position: relative;
    visibility: hidden;
    opacity: 0;
    transform-origin: 50% 50%;
    margin-top: 0;
}

.our-products .product-button,
.skincare-cosmeticproducts .product-button {
    margin-top: 35px;
}

.our-products .product-button button.btn,
.skincare-cosmeticproducts .product-button button.btn {
    position: absolute;
}

.our-products .product:hover .product-button,
.skincare-cosmeticproducts .product:hover .product-button {
    visibility: visible;
    opacity: 1;
    margin-top: 19px;
    margin-bottom: 50px;
}

.our-products .card.products,
.skincare-cosmeticproducts .card.products {
    border-radius: 9px;
}

.our-products .view-all button.btn,
.skincare-cosmeticproducts .view-all button.btn {
    padding: 17px 65px !important;
}

.button-nina {
    position: relative;
    overflow: hidden;
    line-height: 1.3;
    /* transition: background 0.3s 0.01s, border-color 0.3s 0.01s; */
}

.button-secondary,
.button-secondary:focus {
    color: #ffffff;
    background-color: #ff6f80;
    border-color: #ff6f80;
}

.button {
    position: relative;
    display: inline-block;
    max-width: 100%;
    padding: 9px 30px;
    min-width: 210px;
    font-family: "Oswald", Helvetica, Arial, sans-serif;
    font-weight: 400;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    border: 2px solid;
    border-radius: 35px;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    transition: .25s all ease;
    white-space: pre-wrap;
}

.button-nina>span:first-child {
    padding-left: 4px;
}

.button-nina>span {
    position: relative;
    overflow: hidden;
    /* display: inline-block; */
    line-height: 24px;
    text-align: center;
    opacity: 0.01;
    color: inherit;
    transform: translate3d(0, -10px, 0);
    transition: 0.22s;
    transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
    display: inline-block;
    transition: opacity 0.22s ease, transform 0.22s ease, color 0.22s ease;
    opacity: 0;
    transform: translateY(10px);
}

.button-nina:hover>span {
    opacity: 1;
    transform: translateY(0);
}

.button-secondary:hover,
.button-secondary:active,
.button-secondary:hover {
    color: #ffffff;
    background-color: #16b6d2;
    border-color: #16b6d2;
}

.button-nina .button-original-content {
    position: absolute;
    z-index: 1;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    padding: inherit;
    transform: translate3d(0, 0, 0);
    letter-spacing: 0.12em;
    min-width: inherit;
    background-color: transparent;
    transition: opacity 0.22s ease, transform 0.22s ease, color 0.22s ease;
}

.button-nina:hover .button-original-content {
    opacity: 1;
    transform: translateY(0);
}

/* .button-original-content:hover {
    transition: background 0.22s ease 0s, color 0.22s ease 0s, transform 0.22s ease 0.51s !important;
}

.single-letter-transition:hover {
    transition: opacity 0.22s ease 0.48s, transform 0.22s ease 0.48s, color 0.22s ease 0s !important;
} */

/* //banner section */

.home-banner-text {
    position: relative;
}

.home-banner-text-btn {
    position: absolute;
    bottom: 110px;
    margin: 0 75px;
}

/* .VideoOnesec {
    top: 50%;
    bottom: 50%;
    right: 20%;
    left: 80%;

} */

/* .VideoOnesec button.btn {
    background: transparent;
    border: 2px solid white;
    padding: 6px 12px !important;
} */

.videoTwosec button.btn {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: transparent;
    border: 2px solid white;
    padding: 6px 12px !important;
}



.videoTwosec button.btn:hover {
    border: unset;
    background-color: #00000094 !important;
    color: white !important;
}