/* //manufacturing page css starts */
.unit.blog-lists-div .unit-body.card .card-body {
    /* height: 45vh; */
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 15px;
}

/* .unit.blog-lists-div .border-radius {
    min-height: 330px;
} */

.unit.blog-lists-div .short-description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-wrap;

}

.time-line-vertical {
    /* position: relative;
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
    text-align: left; */
    padding-top: 30px;
    /* padding-bottom: 30px;
    overflow: hidden; */
}

/* .time-line-vertical-element {
    position: relative;
    margin-bottom: 50px;
} */

.unit-spacing-xxl {
    margin-bottom: -10px;
    margin-left: -15px;
}

/* .unit {
    display: flex;
    flex: 0 1 100%;
    margin-bottom: -30px;
    margin-left: -20px;
} */

.unit-spacing-xxl>* {
    margin-bottom: 10px;
    margin-left: 15px;
}

.unit-left,
.unit-right {
    flex: 0 0 auto;
    max-width: 100%;
}

/* .unit>* {
    margin-bottom: 30px;
    margin-left: 20px;
} */

.time-line-time {
    color: #242429;
    letter-spacing: 0.02em;
}

.fadeInLeft {
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
}

.bg-default .time-line-vertical-element:before,
.bg-default .time-line-vertical-element .unit-body:before {
    background-color: #ffffff;
}

.fadeInRight {
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
}

.blog-img-text {
    display: flex;
    gap: 10px;
}

/* //manufacturing page css ends */

/* //manufacturing blog page css starts */

.profile-timestamp {
    display: flex;
    gap: 25px;
}

/* //social media buttons */

.social-media-section {
    gap: 30px;
}

button.btn.social-media-btns {
    color: var(--color-white) !important;
    background-color: unset !important;
    padding: 0 !important;
}

.btn.social-media-btns:hover .icon-bg-red,
.btn.social-media-btns:hover .text-bg-orange {
    background-color: var(--primary-color);
    animation: moveBackground 0.73s ease forwards;
}

@keyframes moveBackground {
    from {
        background-position: left;
    }

    to {
        background-position: right;
    }
}

.icon-bg-red,
.text-bg-orange {
    transition: background-color 0.73s ease;
}

.icon-bg-red {
    background-color: var(--primary-color);
    padding: 10px 15px;
    border-top-left-radius: 35px;
    border-bottom-left-radius: 35px;
}

.text-bg-orange {
    background-color: var(--secondary-color);
    padding: 10px 15px;
    border-top-right-radius: 35px;
    border-bottom-right-radius: 35px;
}

/* //comment section */
.comment-section {
    gap: 20px;
}

.send-comment input,
.send-comment textarea,
.vendor-enquiry-form input,
.vendor-enquiry-form textarea,
.vendor-enquiry-form select {
    border-radius: 35px;
    padding: 18px 25px;
    width: 100%;
    min-height: 60px;
    background-color: var(--color-lightshade-ash);
    border-color: var(--color-lightshade-ash);
    border: 0px;
    outline: 0;
    margin-bottom: 10px;
    font-family: "Lato-Light";
}

.send-comment label,
.vendor-enquiry-form label {
    font-size: 14px;
    margin-left: 25px;
}

.send-comment textarea,
.vendor-enquiry-form textarea {
    height: 150px;
    min-height: 120px;
    max-height: 255px;
    resize: vertical;
}

.send-comment button.btn,
.vendor-enquiry-form button.btn {
    width: 200px;
}

.post-light-time {
    display: block;
    color: var(--color-ash);
}

.comment-footer,
span.comment-header {
    color: var(--primary-color);
    font-weight: 600;
}

/* .unit-left {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
} */

.display-picture-div {
    /* width: 100px; */
    position: relative;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.display-picture-div span {
    color: var(--primary-color);
    font-size: 15px;
}

.manufacturing-blogs-display .recent-posts-section .post-titles:hover {
    color: var(--primary-color);
}

.comment-avatar {
    width: 75px;
    height: auto;
    overflow: hidden;
}

.small-img-div {
    width: 40px;
    overflow: hidden;
    height: auto;
}

/* 
.display-picture-div::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 30vh;
    bottom: -70vh;
    border-left: 1px solid var(--color-grey);
    margin-left: -1px;
}

.display-picture-div .display-img-div::after {
    content: '';
    position: absolute;
    right: -45px;
    top: 0;
    bottom: 5vh;
    border-right: 1px solid var(--color-grey);
    margin-left: -1px;
    transform: rotateZ(90deg);
} */

.posts-container {
    height: auto;
    overflow: hidden;
    width: 100%;
}

.manufacturing-blogs-display .posts-container {
    position: relative;
}

.manufacturing-blogs-display .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.manufacturing-blogs-display .posts-container:hover .image-overlay {
    opacity: 1;
}

.blog-card-margin {
    margin: 0 18px 18px 18px;
}

/* //manufacturing blog page css ends */