/* //footer -section css */

.footer-section {
    background-color: var(--primary-color);
    color: var(--color-white);
}

.footer-section h1,
.footer-section h6,
.footer-section p {
    padding: 0;
    margin: 0;
}

.footer-section h1,
.footer-section ul {
    padding: 0 !important;
    margin: 0;
}

.certification-logo-section {
    gap: 5px;
}

/* .icon-section {
    gap: 25px;
} */

.articles-events {
    gap: 15px;
}

.article-img-div {
    width: 100px;
    overflow: hidden;
    height: 100px;
}