/* //about us page starts */

/* //aboutus card section css starts */
.font-35 {
    font-size: 35px;
}

.aboutus-card-section .team-services {
    gap: 15px;
    align-items: flex-start;
    /* justify-content: center; */
}

.other-page-banner-relative {
    position: relative;
}

.home-banner-text-btn.other-page-banner {
    top: 45%;
    position: absolute;
}

.leaderships .doctors-names {
    color: var(--primary-color);
}

/* //about us page ends */

/* //our team page css starts */

.chairman-description h4 {
    color: var(--color-ash);
    font-size: 1.7rem !important;
}

.our-team-page-banner-css {
    right: 0;
    text-align: right;
}

.get-in-touch input,
.get-in-touch textarea,
.get-in-touch input:-webkit-autofill,
.get-in-touch input:focus-visible {
    border-radius: 22px;
    padding: 20px;
    width: 100%;
    min-height: 75px;
    background-color: var(--color-lightshade-ash);
    border-color: var(--color-lightshade-ash);
    border: 0px;
    outline: 0;
    margin-bottom: 10px;
    font-family: Lato-Light;
}

.get-in-touch textarea {
    min-height: 202px;
    resize: none;
}

/* //our team page css ends */

/* //rnd page css starts */

.rnd-page-banner-css,
.scientific-publicationsBanner-page-banner-css,
.clinicaltrial-page-banner-css,
.oralsupplements-page-banner-css,
.cosmeticproduct-page-banner-css,
.privatelabel-page-banner-css

/* .quality-management-page-banner-css */
    {
    color: var(--color-black);
}

.our-collabrations .services-institute .d-flex {
    gap: 20px;
}

.our-collabrations span.services-institute-description {
    font-size: 15px;
}

.our-collabrations .our-collabrations-description {
    background-color: var(--primary-color);
    padding: 40px;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.our-collabrations .services-institute-div {
    padding: 40px;
    color: var(--color-black);

}

.carousel-card-nav.card.our-rnd-card,
.carousel-card-nav.card.cosmetic-product-card {
    display: flex;
    flex-direction: column;
    overflow: none;
    border: none;
    border-radius: 0px;
    transition: box-shadow .3s ease;

}

.carousel-card-nav.card.our-rnd-card h5 {
    font-size: 16px !important;
}

.row.services-institute {
    margin: auto;
}

.rnd-img-div {
    width: 100%;
    height: auto;
    overflow: hidden;
}

/* //rnd page css ends */

/* //formulation experience page css starts */

.formulation-experience-page-banner-css,
.service-page-banner-css,
.quality-management-page-banner-css {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.box-minimal-modern:hover {
    box-shadow: 0 8px 29px rgba(36, 36, 41, 0.09);
}

.box-minimal-modern {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    background-color: #ffffff;
    position: relative;
    padding: 0 28px 45px;
    margin-top: 50px;
    /* border: 1px solid var(--color-ash); */
    border-radius: 8px;
    transition: 750ms ease-in-out;
}

.box-minimal-modern h6 {
    margin-top: 30px;
}

.box-minimal-modern p {
    margin-top: 22px;
}

.box-minimal-modern .box-minimal-icon {
    /* font-size: 40px; */
    position: relative;
    top: -50px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    margin-bottom: -50px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    color: #ffffff;
    background: var(--primary-color);
    overflow: hidden;
    transition: background-color 750ms ease-in-out;
    opacity: 1;


}

.box-minimal-modern:hover .box-minimal-icon {
    background-image: linear-gradient(90deg, var(--primary-color) 0%, #f44051 100%);
    /* transition: 1500ms ease-in-out; */
}

h6,
.heading-6 {
    line-height: 1.5;
}

.row-50>* {
    margin-bottom: 50px;
}

.tabel-div {
    height: 250px;
    overflow-y: scroll;
}

.tabel-div thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

.tabel-div::-webkit-scrollbar-thumb {
    border-radius: 35px;
}

/* //formulation experience page css starts */

/* //scientific publication page css starts */

.scientific-publicationsBanner-page-banner-css,
.oralsupplements-page-banner-css {
    right: 0;
}

.scientific-publications .card {
    border-radius: 0px;
    border: 2px solid var(--primary-color);
}

.scientific-publications .card-body {
    background-color: var(--primary-color);
    color: var(--color-white);
    min-height: 312px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

/* //scientific publication page css starts */

/* //patents and certificates page css starts */

.patentsCertificates .carousel-card-nav.card .img-container {
    border: 2px solid var(--primary-color);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
}

.patentsCertificates .carousel-card-nav.card h6 {
    background-color: var(--primary-color);
    color: var(--color-white);
    padding: 20px;
    font-size: 18px !important;
}

/* //patents and certificates page css ends */