.select-option {
    color: red;
}

.radio-label {
    display: flex !important;
    font-size: 16px !important;
    margin: 5px !important;
    padding-left: 5px;
}

.radio-btn {
    width: 26px !important;
    height: 26px !important;
    border: unset !important;
    cursor: pointer;
    transition: .22s;
    padding: 0px !important;
    min-height: 0px !important;
    /* border-radius: 50% !important; */
}

.form-check-input:checked {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

input.nds-inputs {
    border: unset !important;
    border-radius: unset !important;
    padding: 0 10px !important;
    min-height: unset !important;
    width: fit-content !important;
    margin-bottom: unset !important;
    background: unset !important;
    border-bottom: 1px solid black !important;
    text-align: center;
}

button.btn:disabled {
    background-color: var(--primary-color);
    color: var(--color-white);
}