/* service landing page css starts */
.service-description h3,
.service-description h3::after {
    text-transform: capitalize;
    border-bottom: 0;
}

.service .service-image-card {
    padding-right: 0px;
    padding-left: 0px;
}

.service .service-description {
    background-color: var(--primary-color);
    padding: 25px 40px;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.border-line .box-minimal-modern {
    border: 1px solid var(--color-ash);
    border-radius: 0px;
}

ul.list-items li {
    list-style-type: disc;
}

.min-height-aricle-card {
    /* min-height: 550px !important; */
}

.our-process-branding .divisions {
    display: flex;
    flex-direction: column;
    /* justify-content: space-evenly; */
}

/* service landing page css ends */

/* /* gentics testing page css starts */
.overflow-scroll-xy p {
    overflow-y: scroll;
    height: 250px;
    overflow-x: hidden;
    padding-right: 5px;
}

.overflow-scroll-xy ::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
.overflow-scroll-xy ::-webkit-scrollbar-thumb {
    background: #757575;
    border-radius: 10px;
}

.gap-20 {
    gap: 20px;
}

.pdfclicksection {
    font-size: 30px;
    color: var(--primary-color);
}

.cosmetic-products-science .state-ofart-laboratory .d-flex {
    gap: 10px;
}


/* genetics testing css ends */