body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



@font-face {
  font-family: "Oswald-Light";
  src: url("../src/assets/fonts/Oswald/static/Oswald-Light.ttf");
  font-weight: 700 !important;
  font-style: normal;
}



@font-face {
  font-family: "Oswald-ExtraLight";
  src: url("../src/assets/fonts/Oswald/static/Oswald-ExtraLight.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../src/assets/fonts/Lato/Lato-Regular.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Light";
  src: url("../src/assets/fonts/Lato/Lato-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "BIZUDPGothic-Bold";
  src: url("../src/assets/fonts/BIZ_UDPGothic/BIZUDPGothic-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}