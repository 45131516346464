/* //navigation-bar css */

/* .nav-section.feasibility-navsection, */
.brand-search-bar.feasibility-navsection {
    box-shadow: 0 4px 12px -4px #9ea6b0;
    position: relative;
    z-index: 1;
}

.navigation-bar {
    background-color: var(--primary-color);
}

.brand-search-bar {
    margin-top: 50px;
}

.navigation-bar {
    gap: 20px;
    /* font-weight: bold; */
}

.dropdown a.sub-sub-menu {
    font-weight: 500;
    padding-left: 25px !important;
}

.navigation-bar a.nav-link {
    position: relative;
}

/* .navigation-bar a.nav-link.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 70px;
    border-bottom: 3.5px solid var(--secondary-color);
} */

.navigation-bar a.nav-link,
a.dropdown-item {
    content: '';
    /* position: absolute; */
    /* bottom: -7px; */
    /* width: calc(100% - 10px); */
    border-bottom: 3.5px solid transparent;
    color: var(--color-white);
    /* transform: translateX(-92%); */
    padding-left: 25px;
    /* width: fit-content; */
    font-weight: bold;
}

.navigation-bar a.nav-link.active {
    border-bottom-color: var(--nav-underline);
    /* width: 100%; */
}

.navigation-bar a.dropdown-item.active {
    border-bottom-color: var(--nav-underline);
    background-color: var(--primary-color);
    color: var(--color-white);
    width: 100%;
    /* position: relative;
    bottom: -8px; */
}

/* .navigation-bar a.dropdown-item.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 70px;
    border-bottom: 3.5px solid var(--secondary-color);
} */

.navigation-bar .dropdown-menu {
    background-color: var(--primary-color);
    color: var(--color-white);
    border: none;
    border-radius: 0;
    padding-bottom: 0px;
    top: 41px;
}

.navigation-bar a.nav-link {
    color: var(--color-white) !important;
    --bs-nav-link-color: none;
    /* margin-left: 10px; */
    /* font-weight: 700; */
}

.nav-link:focus-visible {
    outline: 0;
    box-shadow: none !important;
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--color-white);
    background-color: var(--primary-color);
}

.navigation-bar a.nav-link.active {
    color: var(--color-white);
    --bs-nav-link-color: none;
    /* width: fit-content; */
}

.navbar-toggler:focus {
    box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2) !important;
}

/* .navigation-item .submenu {
    display: none;
    position: absolute;
    background-color: var(--primary-color);
    border: 1px solid #ccc;
    width: 150px;
} */

/* .navigation-bar .navigation-item .submenu a.nav-link::after {
    content: '';
    position: absolute;
    bottom: 0px;
    width: calc(100% - 10px);
    border-bottom: 2.5px solid transparent;
    transform: translateX(-92%);

} */

/* .navigation-bar .navigation-item .submenu a.nav-link.active::after {
    border-bottom-color: var(--nav-underline);
} */

/* .navigation-item .nav-item:hover .submenu {
    display: block;
    list-style-type: none;
} */

.navigation-bar .nav-item {
    position: relative;
}

/* //brand name and search bar css */

.search-bar .search-form {
    position: relative;

}

.search-bar .search-form input {
    /* width: 460px; */
    padding: 10px 20px;
    border-radius: 35px;
    border: 1.5px solid var(--color-ash);
    font-family: Lato-Light;
}

.search-bar .search-form input:focus {
    box-shadow: none;
}

.search-bar button.btn {
    position: absolute;
    right: 0px;
    border-radius: 35px;
    width: 100px;
    padding: 10px 12px;
    background-color: var(--primary-color);
    color: var(--color-white);
    top: 1.5px;
}

.search-bar .search-input {
    position: absolute;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-left: 20px;
    transform: translateY(10px);
    color: var(--color-ash);

}

.search-bar .fa-search {
    font-size: 22px;
}